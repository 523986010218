import React from 'react';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import  './LoaderComponent.css';

const customStyles = {
    content: {
        position: 'absolute',
        //width: '50%',
        top: '40%',
        bottom: '10%',
        padding: '35px',
        overflow: 'none',
        zIndex: '2',
        left: '0%',
        right: '10%',
        //height: '50%',
        border: 'none',
        display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
        
    }
};

const LoaderComponent = (props) => {
    return (
        <Modal isOpen={props.isLoading} ariaHideApp={false} scrollable="true"  style={customStyles}  contentLabel="Loading">
            <div className="containerLoader">
            <div className="boxLoader">
            <ClipLoader color="#0099CC" loading={props.isLoading} text='loading' />
           <br />
      <div className="font-weight-bold h5">Loading...</div>
            </div>
        </div>
       </Modal>
    );
};

export default LoaderComponent;
