export const flagList = [
    
    
    {
        label: "Re-Type Question",
        value: "re_type",
        answer_type: ["TEXT"],
        id: 1,
        checkCondition: false,
        add_load: {},
        condition: true
    },
    {
        label: "To Be Masked",
        value: "is_to_be_mask",
        answer_type: ["TEXT", "NUMBER"],
        id: 2,
        checkCondition: false,
        add_load: {},
        condition: true
    },
    {
        label: "Is Odm",
        value: "is_odm",
        answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
        id: 3,
        checkCondition: true,
        add_load: {},
        condition: (values) => values.is_odm === 'yes'
    },
    {
        label: "Related",
        value: "related",
        answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
        id: 4,
        checkCondition: false,
        add_load: {},
        condition: true
    },
    {
        label: "Required",
        value: "required",
        answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
        id: 5,
        checkCondition: false,
        add_load: {},
        condition: true
    },
    {
        label: "Is Searchable",
        value: "is_to_be_search",
        answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
        id: 6,
        checkCondition: false,
        add_load: {},
        condition: null
    },
    {
        label: "Is Parent Question",
        value: "is_parent",
        answer_type: ["SELECT","RADIO"],
        id: 7,
        checkCondition: true,
        add_load: {},
        condition: (values) => values.is_child === 'no'
    },
    {
        label: "Is Child Question",
        value: "is_child",
        answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
        id: 8,
        checkCondition: true,
        add_load: {},
        condition: (values) => values.is_parent === 'no'
    },
    {
        label: "For Prediction",
        value: "for_prediction_screen",
        answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
        id: 9,
        checkCondition: true,
        add_load: {},
        condition: (values) => values.sections.length - 1 === values.sectionId && values.is_configuration_available
    },
    {
        label: "For ML Model",
        value: "for_ml_model",
        answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
        id: 10,
        checkCondition: true,
        add_load: {},
        condition: (values) =>  values.is_configuration_available
    },
    {
        label: "Trigger API",
        value: "api_call",
        answer_type: ["SELECT"],
        id: 11,
        checkCondition: true,
        add_load: {},
        condition: (values) =>  values.for_prediction_screen === 'yes' && values.is_configuration_available
    },
    {
        label: "Is Answers Dependent",
        value: "is_ans_dep_on_prev_que_ans",
        answer_type: ["SELECT", "RADIO"],
        id: 12,
        checkCondition: true,
        add_load: {deciders_obj: {file_path: '', deciders: ['']}},
        condition: (values) =>  values.is_configuration_available
    },
    {
        label: "Hide this Question",
        value: "is_for_hiding",
        answer_type: ["SELECT","CHECKBOX","RADIO","DATE","FILE","TEXT", "NUMBER"],
        id: 13,
        checkCondition: false,
        add_load: {},
        condition: null
    }

]