export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

export const SAVE_DASHBOARD_REQUEST = 'SAVE_DASHBOARD_REQUEST';
export const SAVE_DASHBOARD_SUCCESS = 'SAVE_DASHBOARD_SUCCESS';
export const SAVE_DASHBOARD_FAILURE = 'SAVE_DASHBOARD_FAILURE';

export const PUBLISH_DASHBOARD_REQUEST = 'PUBLISH_DASHBOARD_REQUEST';
export const PUBLISH_DASHBOARD_SUCCESS = 'PUBLISH_DASHBOARD_SUCCESS';
export const PUBLISH_DASHBOARD_FAILURE = 'PUBLISH_DASHBOARD_FAILURE';

export const DELETE_DASHBOARD_REQUEST = 'DELETE_DASHBOARD_REQUEST';
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_FAILURE = 'DELETE_DASHBOARD_FAILURE';
