export const ADD_NOTIFICATIONS_REQUEST = 'ADD_NOTIFICATIONS_REQUEST'
export const ADD_NOTIFICATIONS_SUCCESS = 'ADD_NOTIFICATIONS_SUCCESS'
export const ADD_NOTIFICATIONS_FAILURE = 'ADD_NOTIFICATIONS_FAILURE'
export const ADD_NOTIFICATIONS_UPDATE = 'ADD_NOTIFICATIONS_UPDATE'
export const PUBLISH_NOTIFICATIONS_REQUEST = 'PUBLISH_NOTIFICATIONS_REQUEST'
export const PUBLISH_NOTIFICATIONS_SUCCESS = 'PUBLISH_NOTIFICATIONS_SUCCESS'
export const PUBLISH_NOTIFICATIONS_FAILURE = 'PUBLISH_NOTIFICATIONS_FAILURE'
export const DELETE_NOTIFICATIONS_REQUEST = 'DELETE_NOTIFICATIONS_UPDATE'
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_UPDATE'
export const DELETE_NOTIFICATIONS_FAILURE = 'DELETE_NOTIFICATIONS_UPDATE'


