export const FETCH_PRE_LOGIN_REQUEST = 'FETCH_PRE_LOGIN_REQUEST';
export const FETCH_PRE_LOGIN_SUCCESS = 'FETCH_PRE_LOGIN_SUCCESS';
export const FETCH_PRE_LOGIN_FAILURE = 'FETCH_PRE_LOGIN_FAILURE';

export const SIGNIN_PIN = 'SIGNIN_PIN';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_UPDATE = 'FETCH_USERS_UPDATE';
export const CLEAR_USER = 'CLEAR_USER';
