import { ADD_NOTIFICATIONS_REQUEST } from './AddNotificationsTypes'
import { ADD_NOTIFICATIONS_SUCCESS } from './AddNotificationsTypes'
import { ADD_NOTIFICATIONS_FAILURE } from './AddNotificationsTypes'
import { ADD_NOTIFICATIONS_UPDATE } from './AddNotificationsTypes'
import { PUBLISH_NOTIFICATIONS_FAILURE } from './AddNotificationsTypes'
import { PUBLISH_NOTIFICATIONS_REQUEST } from './AddNotificationsTypes'
import { PUBLISH_NOTIFICATIONS_SUCCESS } from './AddNotificationsTypes'
import { DELETE_NOTIFICATIONS_FAILURE } from './AddNotificationsTypes'
import { DELETE_NOTIFICATIONS_REQUEST } from './AddNotificationsTypes'
import { DELETE_NOTIFICATIONS_SUCCESS } from './AddNotificationsTypes'

export function addNotificationsRequest(){
    return {
        type : ADD_NOTIFICATIONS_REQUEST
    }
}
export function addNotificationsSuccess(addednotifications){
    return {
      type: ADD_NOTIFICATIONS_SUCCESS,
      addednotifications
    }
}
export function addNotificationsFailure(error){
    return {
        type: ADD_NOTIFICATIONS_FAILURE,
        error
    }
}
export function update(addednotifications){
    return {
        type: ADD_NOTIFICATIONS_UPDATE,
        addednotifications
    }
}

//Publish Notification Action Types
export function publishNotificationsRequest() {
    return {
        type: PUBLISH_NOTIFICATIONS_REQUEST
    };
}
export function publishNotificationsSuccess() {
    return {
        type: PUBLISH_NOTIFICATIONS_SUCCESS
    };
}
export function publishNotificationsFailure() {
    return {
        type: PUBLISH_NOTIFICATIONS_FAILURE
    };
}

// Delete Notification Action Types
export function deleteNotificationsRequest() {
    return {
        type: DELETE_NOTIFICATIONS_REQUEST
    };
}
export function deleteNotificationsSuccess() {
    return {
        type: DELETE_NOTIFICATIONS_SUCCESS
    };
}
export function deleteNotificationsFailure() {
    return {
        type: DELETE_NOTIFICATIONS_FAILURE
    };
}




export default  { addNotificationsRequest ,addNotificationsSuccess, addNotificationsFailure}