import { ADD_KIDREPORT_REQUEST } from './AddKidReportTypes'
import { ADD_KIDREPORT_SUCCESS } from './AddKidReportTypes'
import { ADD_KIDREPORT_FAILURE } from './AddKidReportTypes'
import { ADD_KIDREPORT_UPDATE } from './AddKidReportTypes'
import { PUBLISH_KIDREPORT_FAILURE } from './AddKidReportTypes'
import { PUBLISH_KIDREPORT_REQUEST } from './AddKidReportTypes'
import { PUBLISH_KIDREPORT_SUCCESS } from './AddKidReportTypes'
import { DELETE_KIDREPORT_FAILURE } from './AddKidReportTypes'
import { DELETE_KIDREPORT_REQUEST } from './AddKidReportTypes'
import { DELETE_KIDREPORT_SUCCESS } from './AddKidReportTypes'
import { DOWNLOAD_KIDREPORT_FAILURE } from './AddKidReportTypes'
import { DOWNLOAD_KIDREPORT_REQUEST} from './AddKidReportTypes'
import { DOWNLOAD_KIDREPORT_SUCCESS } from './AddKidReportTypes'
import { REQUEST_KIDREPORTLIST_FAILURE }  from './AddKidReportTypes'
import { REQUEST_KIDREPORTLIST_REQUEST }  from './AddKidReportTypes'
import { REQUEST_KIDREPORTLIST_SUCCESS }  from './AddKidReportTypes'

export function addKidreportsRequest(){
    return {
        type : ADD_KIDREPORT_REQUEST
    }
}
export function addKidreportsSuccess(addedkidreports){
    return {
      type: ADD_KIDREPORT_SUCCESS,
      addedkidreports
    }
}
export function addKidreportsFailure(error){
    return {
        type: ADD_KIDREPORT_FAILURE,
        error
    }
}
export function update(addedkidreports){
    return {
        type: ADD_KIDREPORT_UPDATE,
        addedkidreports
    }
}

//Publish Kidreport Action Types
export function publishKidreportsRequest() {
    return {
        type: PUBLISH_KIDREPORT_REQUEST
    };
}
export function publishKidreportsSuccess() {
    return {
        type: PUBLISH_KIDREPORT_SUCCESS
    };
}
export function publishKidreportsFailure() {
    return {
        type: PUBLISH_KIDREPORT_FAILURE
    };
}

// Download Kidreport Action Types
export function downloadKidreportsRequest() {
    return {
        type: DOWNLOAD_KIDREPORT_REQUEST
    };
}
export function downloadKidreportsSuccess() {
    return {
        type: DOWNLOAD_KIDREPORT_SUCCESS
    };
}
export function downloadKidreportsFailure() {
    return {
        type: DOWNLOAD_KIDREPORT_FAILURE
    };
}

// Delete Kidreport Action Types
export function deleteKidreportsRequest() {
    return {
        type: DELETE_KIDREPORT_REQUEST
    };
}
export function deleteKidreportsSuccess(templateDownload) {
    return {
        type: DELETE_KIDREPORT_SUCCESS,
        templateDownload
    };
}
export function deleteKidreportsFailure() {
    return {
        type: DELETE_KIDREPORT_FAILURE
    };
}

// Request Kidreport list Action Types
export function requestKidreportsListRequest() {
    return {
        type: DELETE_KIDREPORT_REQUEST
    };
}
export function requestKidreportsListSuccess(List) {
    return {
        type: DELETE_KIDREPORT_SUCCESS,
        List
    };
}
export function requestKidreportsListFailure() {
    return {
        type: DELETE_KIDREPORT_FAILURE
    };
}




export default  { addKidreportsRequest ,addKidreportsSuccess, addKidreportsFailure}