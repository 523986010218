import React, {memo } from 'react';
import Select, { components } from 'react-select';

const MultiValue = (props) => {
    const handleRemove = (event) => {
      event.preventDefault();
      props.removeProps.onClick();
     
      // Additional logic or callback function for remove action
    };
  
    const handleOpenPopup = (event) => {
      event.stopPropagation(); // Prevents the click event from propagating to the Select component
      // Additional logic or callback function for opening pop-up
    
    };
  
    return (
      <div>
        <components.MultiValue {...props}>
          <span onClick={handleOpenPopup}>{props.children}</span>
        </components.MultiValue>
      </div>
    );
  };

const DropdownComponent = ({fmlBasicRequirmentList, 
                            handleChange, 
                            name, sectionId, 
                            questionId, 
                            value, dataName}) => {

        const QuestionList = () => {
            let options = [{
                value: '',
                label: (
                    <div data-toggle="tooltip" data-placement="top" title='Select' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                        Select
                    </div>
                ),
                id: ''
            }];
            fmlBasicRequirmentList.map((sec)=> sec.questions
                .map((q, i) => {
                    let exactQuestion = q.question;
                    options.push({
                        value: q.question,
                        label: (
                            <div data-toggle="tooltip" data-placement="top" title={exactQuestion} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                                {exactQuestion}
                            </div>
                        ),
                        id: i
                    });
                }));
            return options;
        };

        
        const SelectedValue = () => {
            let options = [];
            fmlBasicRequirmentList.map((sec)=> sec.questions
                .filter((q, i) => (q.question === value) && (
                    options=[{
                        value: q.question,
                        id: Number(i),
                        label: q.question
                    }]
                )));
            return options;
        };

return (
    <Select 
        key={sectionId} 
        value={SelectedValue()}
        name={name}
        data-id={questionId} 
        data-secid={sectionId} id={name}
        components={{ MultiValue }}
        options={QuestionList()} 
        className="basic-multi-select" 
        data-name={dataName}
        onChange={handleChange(sectionId, questionId)} 
    />
    )

}

export default memo(DropdownComponent);