import React from 'react';
import Select from 'react-select';
import { components } from 'react-select';

const Option = (props) => {

    return (
        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            <components.Option {...props}>
                <input type="checkbox" disabled={props.isDisabled} checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const colourStyles = {
    control: (provided, state) => ({
        ...provided
    }),
    option: (styles, { isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected && 'transparent',
            color: 'black'
        };
    },
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999 // Ensure the menu appears on top of other elements
    }),
    valueContainer: (base) => ({
        ...base,
        maxHeight: 38, // Set the fixed height for the value container
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }),
    singleValue: (base) => ({
        ...base,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    })
};

const MultipleFlagSelect = ({ is_configuration_available, flagOptions, sections, sectionId, questionId, data, handleChange }) => {
    const values = {
        is_odm: sections[sectionId].is_odm,
        is_parent: sections[sectionId].questions[questionId].is_parent,
        is_child: sections[sectionId].questions[questionId].is_child,
        for_prediction_screen: sections[sectionId].questions[questionId].for_prediction_screen,
        sections: sections,
        sectionId: sectionId,
        is_configuration_available: is_configuration_available,
        related: sections[sectionId].questions[questionId].related,
        required: sections[sectionId].questions[questionId].required
    };

    const QuestionList = () => {
        let filteredFlags = flagOptions.filter((item) => (item.checkCondition ? item.condition(values) : item));
        let is_hidden = sections[sectionId].questions[questionId].is_for_hiding === 'yes';

        let options = [];
        filteredFlags.map((item, i) => {
            let exactQuestion = item.label;
            if (item.answer_type.includes(data.answer_type)) {
                if (is_hidden && ['for_ml_model', 'is_for_hiding'].includes(item.value)) {
                    options.push({
                        value: item.value,
                        label: (
                            <div data-toggle="tooltip" data-placement="top" title={exactQuestion} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                                {exactQuestion}
                            </div>
                        ),
                        id: i
                    });
                }
                if (!is_hidden) {
                    options.push({
                        value: item.value,
                        label: (
                            <div data-toggle="tooltip" data-placement="top" title={exactQuestion} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}>
                                {exactQuestion}
                            </div>
                        ),
                        id: i
                    });
                }
            }
        });
        return options;
    };

    const SelectedValue = flagOptions
        .filter((item) => sections[sectionId].questions[questionId]?.[item.value] === 'yes')
        .map((item, index) => {
            const { questions } = sections[sectionId];
            const decidersObj = questions[questionId]?.deciders_obj || null;
            return {
                value: item.value,
                id: index,
                label: item.label,
                payLoad: Object.keys(item.add_load).length !== 0 ? decidersObj : item.add_load,
            };
        });

    const customFilterOption = (option, inputValue) => {
        return option.label?.props?.children[0]?.toLowerCase().includes(inputValue?.toLowerCase());
    };

    return (
        <React.Fragment>
            <Select
                key={sectionId}
                value={SelectedValue}
                styles={colourStyles}
                name="multi_flag_select"
                data-id={questionId}
                data-secid={sectionId}
                id="multi_flag_select"
                options={QuestionList()}
                className="my-custom-select"
                placeholder="Select"
                onChange={(selectedOptions) => handleChange(selectedOptions, sectionId, questionId)}
                isSearchable={true}
                filterOption={customFilterOption}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                components={{
                    IndicatorSeparator: () => null,
                    Option
                }}
            />
        </React.Fragment>
    )
};

export default MultipleFlagSelect;
