export const ADD_KIDREPORT_REQUEST = 'ADD_KIDREPORT_REQUEST'
export const ADD_KIDREPORT_SUCCESS = 'ADD_KIDREPORT_SUCCESS'
export const ADD_KIDREPORT_FAILURE = 'ADD_KIDREPORT_FAILURE'
export const ADD_KIDREPORT_UPDATE = 'ADD_KIDREPORT_UPDATE'
export const PUBLISH_KIDREPORT_REQUEST = 'PUBLISH_KIDREPORT_REQUEST'
export const PUBLISH_KIDREPORT_SUCCESS = 'PUBLISH_KIDREPORT_SUCCESS'
export const PUBLISH_KIDREPORT_FAILURE = 'PUBLISH_KIDREPORT_FAILURE'
export const DELETE_KIDREPORT_REQUEST = 'DELETE_KIDREPORT_UPDATE'
export const DELETE_KIDREPORT_SUCCESS = 'DELETE_KIDREPORT_UPDATE'
export const DELETE_KIDREPORT_FAILURE = 'DELETE_KIDREPORT_UPDATE'
export const DOWNLOAD_KIDREPORT_REQUEST = 'DOWNLOAD_KIDREPORT_REQUEST'
export const DOWNLOAD_KIDREPORT_SUCCESS = 'DOWNLOAD_KIDREPORT_SUCCESS'
export const DOWNLOAD_KIDREPORT_FAILURE = 'DOWNLOAD_KIDREPORT_FAILURE'
export const REQUEST_KIDREPORTLIST_REQUEST = 'REQUEST_KIDREPORTLIST_REQUEST'
export const REQUEST_KIDREPORTLIST_SUCCESS = 'REQUEST_KIDREPORTLIST_SUCCESS'
export const REQUEST_KIDREPORTLIST_FAILURE = 'REQUEST_KIDREPORTLIST_FAILURE'


