import React from 'react';
import '../App.css'
const EIFMSG = (props) => {
    return (

        <div className="cotainer">

            <div className="text-center"><h2>Thanks for your interest.</h2></div>
            <div className="text-center col-sm mb-4"><h2>One of our representatives will contact you soon...</h2></div>
            <div className="mb-3">
                &nbsp;
            </div>
        </div>

    )
}

export default EIFMSG;

