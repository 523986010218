import { ADD_KIDREPORT_REQUEST } from './AddKidReportTypes'
import { ADD_KIDREPORT_SUCCESS } from './AddKidReportTypes'
import { ADD_KIDREPORT_FAILURE } from './AddKidReportTypes'
import { ADD_KIDREPORT_UPDATE }  from './AddKidReportTypes'
import { PUBLISH_KIDREPORT_FAILURE }  from './AddKidReportTypes'
import { PUBLISH_KIDREPORT_REQUEST }  from './AddKidReportTypes'
import { PUBLISH_KIDREPORT_SUCCESS }  from './AddKidReportTypes'
import { DOWNLOAD_KIDREPORT_FAILURE }  from './AddKidReportTypes'
import { DOWNLOAD_KIDREPORT_REQUEST }  from './AddKidReportTypes'
import { DOWNLOAD_KIDREPORT_SUCCESS }  from './AddKidReportTypes'
import { REQUEST_KIDREPORTLIST_FAILURE }  from './AddKidReportTypes'
import { REQUEST_KIDREPORTLIST_REQUEST }  from './AddKidReportTypes'
import { REQUEST_KIDREPORTLIST_SUCCESS }  from './AddKidReportTypes'
const initialstate = {
    loading : true , 
    addedkidreports : [],
    error : '',
    publishing:false,
    templateDownload: false,

}
export function AddKidreportsReducer(state=initialstate ,action) {
    switch(action.type){
        
        case ADD_KIDREPORT_REQUEST :  return {
            ...state,
            loading : true
        }
        case ADD_KIDREPORT_SUCCESS :  return {
            loading : false,
            ...state,
            addedorganizations : action.addedkidreports,
            error : ''
        }
        case ADD_KIDREPORT_FAILURE :  return {
            loading : false,
            ...state,
            addedorganizations : [],
            error : action.payload 
        }
        case ADD_KIDREPORT_UPDATE : return {
            loading : false,
            ...state,
            addedorganizations : action.addedkidreports,
            error : ''
        }
        // Publish kidreport
        case PUBLISH_KIDREPORT_REQUEST:
            return {
                ...state,
                publishing: true
            };
        case PUBLISH_KIDREPORT_SUCCESS:
            return {
                ...state,
                publishing: false
            };
        case PUBLISH_KIDREPORT_FAILURE:
            return {
                ...state,
                publishing: false
            };
        // Download Kid Report
        case DOWNLOAD_KIDREPORT_REQUEST:
            return {
                ...state,
                templateDownload: false
            };
        case DOWNLOAD_KIDREPORT_SUCCESS:
            return {
                ...state,
                templateDownload: action.templateDownload
            };
        case DOWNLOAD_KIDREPORT_FAILURE:
            return {
                ...state,
                templateDownload: action.templateDownload
            };
            // Request Kid Reports
        case REQUEST_KIDREPORTLIST_REQUEST:
            return {
                ...state,
                addedkidreports: []
            };
        case REQUEST_KIDREPORTLIST_SUCCESS:
            return {
                ...state,
                addedkidreports: action.addedkidreports
            };
        case REQUEST_KIDREPORTLIST_FAILURE:
            return {
                ...state,
                addedkidreports: []
            };
           default : return state
    }
}


export default { AddKidreportsReducer  }