export const flexRow = {
    display: 'flex',
    flexRow: 'row'
};

export const wrap = {
    display: 'flex',
    flexRow: 'row',
    boxSizing: 'borderBox'
};

export const mainContent = {
    marginTop: '12px',
    width: '100%',
    boxSizing: 'border-box'
};

export const heading = {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#8284e5'
};

export const subHeading = {
    fontSize: '30px',
    fontWeight: 'bold'
};

export const fullWidth = {
    width: '100%',
    marginBottom: '24px'
};

export const fieldRow = {
    display: 'flex',
    flexRow: 'row',

    justifyContent: 'space-between',
    marginBottom: '16px'
};

export const twoCol = {
    //   @media all and (minWidth:  "520px) {
    margiBottom: '20px',
    marginRight: '8px',
    width: '100%',

    flexDirection: 'column',
    marginBottom: '8px'
};

export const inputField = {
    boxSizing: 'border-box',
    minWidth: '0px',
    display: 'inline',
    backgroundColor: '#f5f5f5',
    borderRadius: '0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid #8284e5',
    width: '100%',
    height: '44px',
    //   -webkit-appearance:  "none",
    fontFamily: 'inherit !important',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
    margin: '0px',
    padding: '8px'
};

export const datePicker = {
    //   ${inputField}
    flexDirection: 'row !important'
    //   & > div {
    //     font-family:  ""Quicksand", Helvetica, sans-serif !important",
    //   }
};

export const label = {
    display: 'block',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#000',
    marginBottom: '5px'
};

export const txtLabel = {
    //   ${label}
    fontSize: '16px'
};

export const label1 = {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#000',
    marginBottom: '5px'
};

export const fieldBox = {
    display: 'inline-block',
    padding: '12px',
    borderBottom: '1px solid #8284e5',
    backgroundColor: '#f5f5f5',
    marginRight: '5px',
    width: '135px'
};
export const fieldBox1 = {
    display: 'inline-block',
    padding: '12px',
    borderBottom: '1px solid #8284e5',
    backgroundColor: '#f5f5f5',
    marginRight: '5px',
    width: '135px',
    height: '45px'
};

export const radioBox = {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    borderBottom: '1px solid #8284e5',
    backgroundColor: '#f5f5f5',
    marginRight: '5px',
    marginBottom: '5px',
    width: '150px'
    //   & label {
    //     margin-left:  "5px",
    //   }
};

export const chkBox = {
    padding: '16px',
    borderBottom: '1px solid #8284e5',
    backgroundColor: '#f5f5f5',
    marginRight: '5px',
    marginBottom: '16px'
};

export const selectField = {
    //inputField,
    boxSizing: 'border-box',
    minWidth: '0px',
    display: 'inline',
    backgroundColor: '#f5f5f5',
    borderRadius: '0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid #8284e5',
    width: '100%',
    height: '44px',
    //   -webkit-appearance:  "none",
    fontFamily: 'inherit !important',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
    margin: '0px',
    padding: '8px',
    backgroundImage: 'linear-gradient(45deg, transparent 50%, #8284e5 50%)',
    //   "linearGradient(135deg, #8284e5 50%, transparent 50%)",

    backgroundPosition: 'calc(100% - 20px) calc(1em + 2px)',
    // " calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em",
    backgroundSize: '5px 5px, 5px 5px, 1px 1.5em',
    backgroundRepeat: 'no-repeat'
};

export const tableRow = {
    cursor: 'pointer'
};

export const tableHeader = {
    backgroundColor: '#e3e4fb',
    fontWeight: 'bold'
};

export const dataTable = {
    //   & th,
    //   & td {
    //     fontFamily:  ""Quicksand", Helvetica, sans-serif !important",
    //   }
};

export const panel = {
    flexDirection: 'column'
};

export const panelHeader = {
    backgroundColor: '#e3e4fb !important'
};

export const panelHeading = {
    fontWeight: 'bold',
    fontSize: '18px',
    backgroundColor: '#e3e4fb !important'
};

export const txtDetail = {
    color: '#8284e5',
    fontWeight: 'bold'
};
