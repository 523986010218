
import React, { useEffect, useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'
import { logOut } from '../api/api';
import Modal from 'react-modal';
import '../App.css';

const timeout = 1000 * 60 * 20
//const timeout = 10_000
const promptBeforeIdle = 4_000

const customStyles = {
    content: {
        top: '20%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '15%',
        border: '1px solid rgb(235 72 72)',
        boxShadow: '0px 2px 16px 0px #f44336'
    }
};


export default function IdleTimerComponent() {
  const [state, setState] = useState('Active')
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)
  const history = useHistory();
  let token = localStorage.refreshToken ? (localStorage.refreshToken === undefined ? '' : localStorage.refreshToken === 'undefined' ? '' : localStorage.refreshToken) : '';

  const onIdle = () => {
    if(token !== '') {
    setState('Idle')
    setOpen(false)
    }
  }

  const onActive = () => {
    if(token !== '') {
    setState('Active')
    setOpen(false)
    }
  }

  const onPrompt = () => {
    if(token !== '') {
    setState('Prompted')
    setOpen(true)
    }
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })
 
  useEffect(() => {
     if(state === 'Idle' && token !== '') {
      localStorage.setItem('sessionOut', 'true');
        logOut();
     }
  }, [state])

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })


  return (
    <Fragment>
      {token && 
        <Modal
                        isOpen={open}
                        ariaHideApp={false}
                        onRequestClose={()=>setOpen(false)}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                       <div style={{display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}><h3>Your session has expired. You have been logged out.</h3></div>
                    </Modal>
     }
    </Fragment>
  )
}
