import React, { useState, useEffect } from 'react';
import { DownloadKidReportTemplate, getKidReportsList, AddKidReport, getKidReportDetails, DeleteKidReport, ActiveKidReport, fetchConfiguredQuestionsForNotifications, UpdateKidReport, FetchPreviewKidReport } from '../../api/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { BeatLoader } from 'react-spinners';
import { BsTrash, BsPencilSquare, BsUpload, BsDownload } from 'react-icons/bs';
import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';
import Dropzone from 'react-dropzone';
import './KidReport.css';

const Option = (props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const colourStyles = {
    option: (styles, { isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected && 'transparent',
            color: 'black'
        };
    },
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999 // Ensure the menu appears on top of other elements
    }),
    valueContainer: (base) => ({
        ...base,
        maxHeight: 38, // Set the fixed height for the value container
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }),
    singleValue: (base) => ({
        ...base,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    })
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
toast.configure();
const KidReportConfigureForm = (props) => {
   
    const [state, setState] = useState({
        displayLink: '',
        conditions: [{ section: '', question: '', answer_type: '', questionsList: [], answersList: [], selectedAnswers: [], comparison: '==', grouping: 'yes' }],
        context: [{ section: '', question: '', questionsList: [] }],
        time_filter: '1',
        date_field: '',
        days_count: null,
        comparison: '',
        sections: [],
        showConfirmModal: false,
        action: '',
        showMessage: false,
        kidReportsList: [],
        showLoader: false,
        selectedKidReport: null,
        customerId: null,
        selectedOptions: [],
        fileName: '',
        fileId: ''
    });

    const [selectedFile, setSelectedFile] = useState(null);

    const setInitialState = () => {
        setState((prevState) => ({
            ...prevState,
            displayLink: '',
            conditions: [{ section: '', question: '', answer_type: '', questionsList: [], answersList: [], selectedAnswers: [], comparison: '==', grouping: 'yes' }],
            context: [{}],
            time_filter: '1',
            date_field: '',
            days_count: 0,
            comparison: '',
            selectedKidReport: null,
            action: '',
            fileName: '',
            fileId: ''
        }));
    };

    useEffect(() => {
        const { id } = props.match.params;
        
        if (id) {
           fetchData();
        }
      }, []);
      
      const fetchData = async () => {
        const { id, Config } = props.match.params;
        try {
          let response = await fetchConfiguredQuestionsForNotifications(id, Config);
          let dateQuestions = response?.flatMap((section) =>
            section.questions.filter((item) => item.answer_type === 'DATE')
          );
  
         await setState((prevState) => ({
            ...prevState,
            customerId: id,
            sections: response,
            datequestions: dateQuestions || [],
            context: [{}],
          }));
  
          FetchKidReportsList();
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error as needed
        }
      };
   
    const downloadTemplate = async() => {
        
         await DownloadKidReportTemplate(function(result) {
            if(result) {
                toast.success(`Template file downloaded successfully.`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            } else {
                toast.error(`Template file downloaded Failed.`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            }
         });
    }

    const previewTemplate = async() => {
        
        await FetchPreviewKidReport(state.fileId, state.fileName, function(result) {
            
           if(result) {
               toast.info(`Template file downloaded successfully.`, {
                   position: toast.POSITION.TOP_CENTER,
                   autoClose: 3000
               });
           } else {
            
             toast.error(`Template file downloaded Failed.`, {
                   position: toast.POSITION.TOP_CENTER,
                   autoClose: 3000
               });
           }
        });
   }

    const addRow = (fieldname) => {
        if (fieldname === 'conditions') {
            setState((prevState) => ({
                ...prevState,
                conditions: [...state.conditions, { section: '', question: '', answer_type: '', questionsList: [], answersList: [], selectedAnswers: [], comparison: '', grouping: 'yes' }]
            }));
        } else {
            setState((prevState) => ({ ...prevState, context: [...state.context, { section: '', question: '', questionsList: [] }] }));
        }
    };

    const removeRow = (index, fieldname) => {
        if (fieldname === 'conditions') {
            const rows = [...state.conditions];
            rows.splice(index, 1);
            setState((prevState) => ({
                ...prevState,
                conditions: rows
            }));
        } else {
            const rows = [...state.context];
            rows.splice(index, 1);
            setState((prevState) => ({
                ...prevState,
                context: rows
            }));
        }
    };

    const handleClose = () => {
        setState((prevState) => ({ ...prevState, showConfirmModal: false }));
    };
    //based upon particular action calling respective methods
    const handleKidReports = () => {
        const action = state.action;
        if (action === 'submit') {
            addKidReport();
        } else if (action === 'delete') {
            confirmDeleteKidReport();
        } else {
            confirmPublishKidReport();
        }
    };

    const handleSelectChange = (index, fieldname) => (evnt) => {
        if (fieldname === 'conditions') {
            const { name, value } = evnt.target;
            const list = [...state.conditions];
            list[index][name] = value;
            setState((prevState) => ({ ...prevState, conditions: list }));
            if (name === 'section') {
                if (value.length > 0) {
                    const selectedSectionDetails = state.sections.filter((item) => item.section === value);
                    const selectedSectionRelatedQuestions = selectedSectionDetails.length > 0 && selectedSectionDetails[0].questions;
                    //Remove questions of answerType date
                    const filteredQuestions = selectedSectionRelatedQuestions.filter((ques) => ques.answer_type !== 'DATE');
                    list[index]['questionsList'] = filteredQuestions;
                    setState((prevState) => ({ ...prevState, conditions: list }));
                } else {
                    list[index] = { section: '', question: '', answer: '', answer_type: '', questionsList: [], answersList: [] };
                    setState((prevState) => ({ ...prevState, conditions: list }));
                }
            } else if (name === 'question') {
                if (value.length > 0) {
                    const selectedQuestionDetails = list[index].questionsList.filter((item) => item.question === value);
                    const selectedQuestionAnswers = selectedQuestionDetails.length > 0 && selectedQuestionDetails[0].suggested_answers;
                    const selectedQuestionAnswerType = selectedQuestionDetails.length > 0 && selectedQuestionDetails[0].answer_type;
                    list[index]['answer_type'] = selectedQuestionAnswerType;
                    list[index]['answersList'] = selectedQuestionAnswers;
                    setState((prevState) => ({ ...prevState, conditions: list }));
                } else {
                    list[index]['answer_type'] = '';
                    list[index]['answer'] = '';
                    list[index]['answersList'] = [];
                    setState((prevState) => ({ ...prevState, conditions: list }));
                }
            }
        } else {
            const { name, value } = evnt.target;
            const list = [...state.context];
            list[index][name] = value;
            
            setState((prevState) => ({ ...prevState, context: list }));
            if (name === 'section') {
                if (value.length > 0) {
                    const selectedSectionDetails = state.sections.filter((item) => item.section === value);
                    const selectedSectionRelatedQuestions = selectedSectionDetails.length > 0 && selectedSectionDetails[0].questions;
                    list[index]['questionsList'] = selectedSectionRelatedQuestions;
                    setState((prevState) => ({ ...prevState, context: list }));
                } else {
                    list[index] = { section: '', question: '', answer: '', answer_type: '', questionsList: [], answersList: [] };
                    setState((prevState) => ({ ...prevState, conditions: list }));
                }
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const onCheckBoxChange = (index) => (e) => {
        const { value } = e.target;
        const list = [...state.conditions];
        if (value === 'yes') {
            list[index]['grouping'] = 'no';
            setState((prevState) => ({ ...prevState, conditions: list }));
        } else {
            list[index]['grouping'] = 'yes';
            setState((prevState) => ({ ...prevState, conditions: list }));
        }
    };

    const editKidReportDetails = async () => {
        const { id } = props.match.params;
        const conditions = state.conditions?.map((item) => {
            let answervalues = [];
            const filteredArray = item.answersList.filter((answer) => item.selectedAnswers.some((filterItem) => filterItem.value === answer.id));
            const selectedIds = filteredArray.map((ob) => (!isNaN(parseInt(ob.id)) ? parseInt(ob.id) : ob.id));
            const selectedValues = filteredArray.map((obj) => obj.value);
            answervalues = item.answer_type === 'NUMBER' || item.answer_type === 'TEXT' ? selectedValues : selectedIds;
            return {
                question: item.question,
                values: answervalues,
                comparison: item.comparison,
                grouping: item.grouping
            };
        });
        const filteredcontext = state.context?.reduce((accumulated, item, index) => {
            accumulated[`field_${index+1}`] = item.question;
            return accumulated;
          }, {});
        const data = {
            customer: id,
            name: state.displayLink,
            conditions: conditions,
            context: filteredcontext,
            time_filter: parseInt(state.time_filter),
            date_field: state.date_field,
            days_count: state.days_count ? parseInt(state.days_count) : 0,
            comparison: state.comparison
        };
        let uploadedFile = selectedFile ? selectedFile : state.fileName;
        try {
            const response = await UpdateKidReport(data, state.selectedKidReport.id, uploadedFile);
            if (response.status === 'failed') {
                if (response.status === "failed") {
                    toast.warning(response?.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000
                    });
                } else {
                    toast.error('Unable to update kid report.', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000
                    });
                }
            } else {
                toast.info(`KidReport updated successfully.`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
                FetchKidReportsList();
            }
        } catch (e) {
            toast.error(`Unable to update kid report.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    const handleFormEdit = () => {
        const reqconditions = state.conditions?.some((obj) => obj.hasOwnProperty('question') && obj.question && obj.hasOwnProperty('comparison') && obj.comparison);
        const reqcontext = state.context?.some((obj) => obj.hasOwnProperty('question') && obj.question);
        const reqdayscount = state.days_count !== '';

        const conditions = parseInt(state.time_filter) === 2 ? reqconditions && reqcontext && reqdayscount : reqconditions && reqcontext;
        if (conditions) {
            editKidReportDetails();
        } else {
            toast.info(`Please fill out the required fields`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    const handleFormSubmit = () => {
        setState((prevState) => ({ ...prevState, action: 'submit' }));
        const reqconditions = state.conditions?.some((obj) => obj.hasOwnProperty('question') && obj.question && obj.hasOwnProperty('comparison') && obj.comparison);
        const reqcontext = state.context?.some((obj) => obj.hasOwnProperty('question') && obj.question);
        const reqdayscount = state.days_count !== '';
        const conditions = parseInt(state.time_filter) === 2 ? reqconditions && reqcontext && reqdayscount : reqconditions && reqcontext;
        if (conditions) {
            setState((prevState) => ({ ...prevState, showConfirmModal: true }));
        } else {
            toast.info(`Please fill out the required fields`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    const addKidReport = async () => {
        const { id } = props.match.params;
        const conditions = state.conditions?.map((item) => {
            let answervalues = [];
            const filteredArray = item.answersList.filter((answer) => item.selectedAnswers.some((filterItem) => filterItem.value === answer.id));
            const selectedIds = filteredArray.map((ob) => (!isNaN(parseInt(ob.id)) ? parseInt(ob.id) : ob.id));
            const selectedValues = filteredArray.map((obj) => obj.value);
            answervalues = item.answer_type === 'NUMBER' || item.answer_type === 'TEXT' ? selectedValues : selectedIds;
            return {
                question: item.question,
                values: answervalues,
                comparison: item.comparison,
                grouping: item.grouping
            };
        });

        // const filteredcontext = state.context?.map((item, index) => {[`field_${index+1}`]: item.question});
        const filteredcontext = state.context?.reduce((accumulated, item, index) => {
            accumulated[`field_${index+1}`] = item.question;
            return accumulated;
          }, {});
       
        const data = {
            customer: id,
            name: state.displayLink,
            conditions: conditions,
            context: filteredcontext,
            time_filter: parseInt(state.time_filter),
            date_field: state.date_field,
            days_count: state.days_count ? parseInt(state.days_count) : 0,
            comparison: state.comparison
        };
        try {
            const response = await AddKidReport(data, selectedFile);
            if (response.status === 'failed') {
                toast.warning(response?.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });

                handleClose();
            } else {
                toast.info(`KidReport configured successfully.`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
                handleClose();
                setInitialState();
                FetchKidReportsList();
            }
        } catch (e) {
            toast.error(`Unable to configure kid report.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
            handleClose();
        }
    };

    const FetchKidReportsList = async () => {
        setState((prevState) => ({
            ...prevState,
            showLoader: true
        }));
        const { id, Config } = props.match.params;
        try {
            let kidreports = await getKidReportsList(id);
            setState((prevState) => ({ ...prevState, kidReportsList: kidreports, showLoader: false }));
        } catch (e) {
            setState((prevState) => ({ ...prevState, showLoader: false }));
        }
    };

    const singleKidReportDetails = async (item) => {
        setState((prevState)=>({...prevState, action: 'edit', selectedKidReport: item }));
        let response = await getKidReportDetails(item.id);
        if(response?.status === 'failed') {

            toast.info(`Unable to get kid reports details`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        } else {
            let selectedConditions = [];
            const selectedCondition = response?.conditions.map((item) => {
                const section = state.sections.filter((obj) => obj.questions.some((ques) => ques.question === item.question));

                const selectedQues = section[0]?.questions.filter((ques) => ques.question === item.question);
                const answer_type = selectedQues[0]?.answer_type;
                const answerlist = selectedQues[0]?.suggested_answers;
                const questionslist = section[0]?.questions;
                const filteredQuestionslist = questionslist.filter((ques) => ques.answer_type !== 'DATE');
                const selectedAnswers = answer_type === 'NUMBER' || answer_type === 'TEXT' ? answerlist.filter((answer) => item.values.some((filterItem) => filterItem === answer.value)) : answerlist.filter((answer) => item.values.some((filterItem) => filterItem === answer.id));
                const formattedSelectedAnswers = selectedAnswers.map((answer) => {
                    return { value: answer.id, label: answer.value === '' ? 'Not Answered' : answer.value };
                });
                selectedConditions.push({
                    question: item.question,
                    section: section[0]?.section,
                    questionsList: filteredQuestionslist,
                    answersList: answerlist,
                    answer_type: answer_type,
                    comparison: item.comparison,
                    grouping: item.grouping,
                    selectedAnswers: formattedSelectedAnswers,
                    values: item.values
                });
                return selectedConditions;
            });

            const selectedSelection = Object.values(response?.context).map((item, index) => {
                let selectedSelection = [];
                const section = state.sections.filter((section) => section.questions.some((element) => element.question === item));
                const questionslist = section[0]?.questions;
                selectedSelection.push({ section: section[0]?.section, question: item, questionsList: questionslist });
                return selectedSelection;
            });

            const context = selectedSelection.flat(1);
            const filePath = response?.template_path?.substring(response?.template_path?.lastIndexOf('/') + 1);
            const Id = response?.id 
            setState((prevState) => ({
                ...prevState,
                displayLink: response?.name,
                conditions: selectedConditions,
                context: context,
                fileName: filePath,
                fileId: Id
            }));
        }
    };

    const handleDeleteKidReport = (item) => {
        setState((prevState) => ({ ...prevState, showConfirmModal: true, selectedKidReport: item, action: 'delete' }));
    };

    const handlePublishKidReport = (item) => {
        setState((prevState) => ({ ...prevState, showConfirmModal: true, selectedKidReport: item, action: 'publish' }));
    };

    const confirmDeleteKidReport = async () => {
        const id = props.match.params;
        const item = state.selectedKidReport;
        if (item.is_published === 'yes') {
            toast.warning('Published kid report cannot be deleted', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            handleClose();
        } else {
            try {
                let response = await DeleteKidReport(item.id);
                if (response.status === 'failed') {
                    toast.error('Delete kid report failed', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                    handleClose();
                } else {
                    toast.info('Kid Report deleted successfully', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                    handleClose();
                    FetchKidReportsList();
                    //setInitialState();
                }
            } catch (error) {
                toast.error('Delete kid report failed', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                handleClose();
            }
        }
    };
    const confirmPublishKidReport = async () => {
        const item = state.selectedKidReport;
        const publishValue = item.is_active === 'no' ? 'yes' : 'no';
        const successMessage = item.is_active === 'no' ? 'KidReport activated successfully' : 'KidReport in activated successfully';
        const errorMessage = item.is_active === 'no' ? 'Unable to activate kid report' : 'Unable to In activate kid report';
        try {
            let response = await ActiveKidReport(item.id, publishValue);
            if (response.status === 'failed') {
                toast.info(errorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                handleClose();
            } else {
                toast.info(successMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                handleClose();
                FetchKidReportsList();
                //setInitialState();
            }
        } catch (error) {
            toast.info(errorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            handleClose();
        }
    };

    const handleMultiSelectChange = (selectedOption, index) => {
        const name = 'selectedAnswers';
        const list = [...state.conditions];
        list[index][name] = selectedOption;
        setState((prevState) => ({ ...prevState, conditions: list }));
    };

    const filterSelectedQuestions = (index) => {
        let result = state.conditions.find((item, ind) => index !== ind)?.question;

        return result;
    };

    const onDrop = (acceptedFiles) => {
       
        setSelectedFile(acceptedFiles[0]);
        setState((prevState)=>({...prevState, fileName: acceptedFiles[0]?.name}))
    };

    
   
    return (
        <div className="container-fluid mb-5" style={{ marginTop: '3rem' }}>
            <div className="row p-2 bg-primary text-white font-weight-bold m-3">Kid Report Configuration</div>
            <div className="col-12">

                <div className="form-group field-row">
                        <label className="col-sm-2 col-form-label font-weight-bold ">Title:</label>
                        <div className="col-sm-10">
                            <input type="text" onChange={handleChange} value={state.displayLink} name="displayLink" required className="form-control" />
                        </div>
                    </div>
                    {state.conditions.map((item, index) => (
                        <div key={index}>
                            <div className="form-group field-row" style={{ marginBottom: '2px' }}>
                                <label className="col-sm-2 col-form-label font-weight-bold ">Condition :</label>
                                <div className="col-sm-2">
                                    <select name="section" className="form-control" value={item.section} onChange={handleSelectChange(index, 'conditions')} required>
                                        <option value="">Select Section</option>
                                        {state.sections.map((item, index) => {
                                            return (
                                                <option key={index} value={item.section}>
                                                    {item.section}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-sm-2">
                                    <select name="question" className="form-control" value={item.question} onChange={handleSelectChange(index, 'conditions')} required>
                                        <option value="">Select Question</option>
                                        {item.questionsList.map(
                                            (element, i) =>
                                                !filterSelectedQuestions(index)?.includes(element.question) && (
                                                    <option key={i} value={element.question}>
                                                        {element.question}
                                                    </option>
                                                )
                                        )}
                                    </select>
                                </div>
                                <div className="col-sm-2">
                                    <select value={item.comparison} name="comparison" onChange={handleSelectChange(index, 'conditions')} className="form-control" required>
                                        <option value="">Select Comparison</option>
                                        <option value="==">==</option>
                                        <option value="!=">!=</option>
                                    </select>
                                </div>

                                <div className="col-sm-3">
                                    <span className="d-inline-block" data-toggle="popover" data-trigger="focus" data-content="answer" style={{ width: '100%' }}>
                                        <ReactSelect
                                            options={item.answersList.map((option) => {
                                                if (option.value === '') {
                                                    return { value: option.id, label: 'Not Answered' };
                                                } else {
                                                    return { value: option.id, label: option.value };
                                                }
                                            })}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                IndicatorSeparator: () => null,
                                                Option
                                            }}
                                            onChange={(selectedOption) => handleMultiSelectChange(selectedOption, index)}
                                            allowSelectAll={true}
                                            menuPortalTarget={document.body}
                                            value={item.selectedAnswers}
                                            styles={colourStyles}
                                            placeholder="Select Answer"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-11" style={{ textAlign: 'right' }}>
                                <input className="form-check-input" style={{ marginLeft: '0.75rem' }} checked={item.grouping === 'yes' ? true : false} type="checkbox" value={item.grouping} onChange={onCheckBoxChange(index)} />
                                <label className="form-check-label" style={{ marginLeft: '2rem' }}>
                                    Required
                                </label>
                            </div>
                        </div>
                    ))}

                    {state.context.map((item, index) => (
                        <div key={index} className="form-group field-row">
                            <label className="col-sm-2 col-form-label font-weight-bold ">Field {index + 1}:</label>
                            <div className="col-sm-3">
                                <select name="section" value={item.section} className="form-control" onChange={handleSelectChange(index, 'context')} required>
                                    <option value="">Select Section</option>
                                    {state.sections.map((item, index) => {
                                        return (
                                            <option key={index} value={item.section}>
                                                {item.section}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="col-sm-3">
                                <select name="question" value={item.question} className="form-control" onChange={handleSelectChange(index, 'context')} required>
                                    <option value="">Select Question</option>
                                    {item.questionsList?.map((element, index) => {
                                        return (
                                            <option key={index} value={element.question}>
                                                {element.question}
                                            </option>
                                        );
                                    })}
                                </select>

                                {index !== 0 && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '1px',
                                            right: '-15px',
                                            width: '25px',
                                            height: '25px',
                                            fontSize: '24px',
                                            color: 'red'
                                        }}
                                        className="font-text-bold text-center "
                                        onClick={() => removeRow(index, 'context')}
                                    >
                                        <i className="fa fa-remove"></i>
                                    </div>
                                )}

                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '1px',
                                        right: '-40px',
                                        width: '25px',
                                        height: '25px',
                                        fontSize: '24px'
                                    }}
                                    className="font-text-bold text-center "
                                    onClick={() => addRow('context')}
                                >
                                    <i
                                        className="fa fa-plus"
                                        style={{
                                            fontSize: '28px'
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    ))}
                     <div className="form-group field-row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <label className="form-check-label" style={{color:'red'}}>Download sample template and Update desired content and Upload for Kid Report. </label>
                     </div>
                    <div className="form-group field-row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-sm-2">
                            <button type="button" onClick={() => downloadTemplate()} className="btn btn-primary font-weight-bold btn-block report_button">
                                        <BsDownload /> &nbsp;
                                        <label className="form-check-label">Download</label>
                                    </button>
                            </div>
                            <div className="col-sm-2">
                            <Dropzone onDrop={onDrop} accept="image/*">
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                <button  className="btn btn-primary font-weight-bold btn-block report_button" data-toggle="tooltip" data-placement="top" title="Upload Configuration">
                                <BsUpload /> &nbsp;
                                <label className="form-check-label">Upload</label>
                                </button>
                            </div>
                        )}
                    </Dropzone>
                            </div>
                            <div className="col-sm-2">
                            <button type="button" disabled = {state.fileId === ''} onClick={() => previewTemplate()}  className="btn btn-primary font-weight-bold btn-block report_button">
                                        <label className="form-check-label">Preview</label>
                                    </button>
                            </div>
                        </div>
                        {state.fileName && 
                        <div className="form-group field-row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-sm-2">
                            &nbsp;
                            </div>
                            <div className="col-sm-2">
                            <small style={{color: 'red'}}>{state.fileName}</small>
                            </div>
                            <div className="col-sm-2">
                            &nbsp;
                        </div>
                        </div>
                       }
                    <div className="form-group field-row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-sm-2">
                            <button type="button" onClick={() => setInitialState()} className="btn btn-primary font-weight-bold btn-block">
                                        Clear
                                    </button>
                            </div>
                            <div className="col-sm-2">
                            <button type="button" disabled={!state.fileName} onClick={state.action === 'edit' ? handleFormEdit : handleFormSubmit} className="btn btn-primary font-weight-bold btn-block">
                                        {state.action === 'edit' ? 'Update' : 'Submit'}
                                    </button>
                            </div>
                        </div>
                    

                    {state.showLoader ? (
                        <div>
                            <div className="text-center">
                                <BeatLoader size={12} margin={2} color="#0099CC" />
                            </div>
                        </div>
                    ) : (
                        <div className="table-responsive ">
                            <table className="table table-striped mt-5 ">
                                <thead className="bg-info text-white">
                                    <tr style={{ background: '#007bff' }}>
                                        <th bg-color="#007bff" scope="col mb-6">
                                            Name
                                        </th>
                                        <th bg-color="#007bff" scope="col mb-4">
                                            Status
                                        </th>
                                        <th bg-color="#007bff" scope="col mb-4">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.kidReportsList.length > 0 ? (
                                        state.kidReportsList?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.is_active === 'no' ? 'Inactive' : 'Active'}</td>
                                                    <td>
                                                        <BsPencilSquare fontSize={25} style={{ marginRight: '10px' }} onClick={() => singleKidReportDetails(item)} />
                                                        <BsTrash fontSize={25} onClick={() => handleDeleteKidReport(item)} style={{ marginRight: '10px' }} />
                                                        <button type="button" onClick={() => handlePublishKidReport(item)} className="btn btn-primary font-weight">
                                                            {item.is_active === 'no' ? 'Activate' : 'InActivate'}
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={3} className="text-center">
                                                Kid Report not yet configured
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
            </div>
            <Modal isOpen={state.showConfirmModal} onRequestClose={handleClose} style={customStyles} ariaHideApp={false} contentLabel="Forgot Password">
                <h4 className="text-primary">Are you sure to {state.action === 'submit' ? 'submit' : state.action === 'delete' ? 'delete' : state.selectedKidReport?.is_published === 'no' ? 'activate' : 'in activate'} this kid report?</h4>
                <div className="form-group row d-flex justify-content-center"></div>
                <div className="row ">
                    <div className="col-6 text-center ">
                        <button type="button" className="button-pop" onClick={handleKidReports}>
                            Yes
                        </button>
                    </div>
                    <div className="col-6 text-center ">
                        <button type="button" className="button-pop" onClick={handleClose}>
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default KidReportConfigureForm;
