import React from 'react';
import '../App.css'
const RAFMSG = (props) => {
    return (

        <div className="cotainer">

            <div className="text-center"><h2>Thank you for completing the form. A representative will review the responses and contact you soon.</h2></div>
           
            <div className="mb-3">
                &nbsp;
            </div>
        </div>

    )
}

export default RAFMSG;

